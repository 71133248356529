//
//  App
//

.app {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	// Mobile Status
	@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
		overflow: hidden;
	}
}

.nice-dates-day {
	max-height: 65px;
}

.nice-dates-grid {
	max-height: 420px
}

.nice-dates-navigation {
	height: 55px;
}

[theme=dark] {
	.nice-dates-day:not(.-outside) .nice-dates-day_date {
		color: #fff;
	}
	.nice-dates-navigation_current {
		color: #fff;
	}
} 


.nice-dates-day.-holiday > .nice-dates-day_date {
	background-color: var(--bs-danger);
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 50px;
	color: white
}

.nice-dates-day.-extraordinary > .nice-dates-day_date {
	background-color: var(--bs-black);
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 50px;
	color: white
}