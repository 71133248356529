// stylelint-disable-next-line annotation-no-unknown
$notification-types: (
	'default': $dark,
	'success': $success,
	'info': $info,
	'warning': $warning,
	'danger': $danger,
	'awesome': $primary,
	'orange': $orange
) !default;
