@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css');

.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (min-width: 768px) {
    padding: 3rem;
  }
}

.login-card-wrapper {
  width: 100%;
  max-width: 450px;
  perspective: 1000px;
}

.login-card {
  transition: all 0.3s ease;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transform-style: preserve-3d;
  margin-top: 2rem; /* Aggiunto spazio sopra per centrare meglio */
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
  
  &.light {
    background: white;
  }
  
  &.dark {
    background: #242830;
  }
}

.login-logo {
  max-height: 150px; /* Ridotto da 180px a 150px per avere più spazio */
  width: auto;
  margin: 0 auto;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
}

.login-form {
  .form-group {
    position: relative;
    margin-bottom: 1.5rem;
    min-height: 80px; /* Altezza minima per garantire spazio per messaggi di errore */
    
    &.is-invalid {
      .login-input {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }
  
  .login-input {
    height: 54px;
    min-height: 54px; /* Aggiunto min-height per mantenere l'altezza costante */
    border-radius: 10px;
    padding: 0.625rem 1rem;
    font-size: 1rem;
    border: 1px solid #e2e8f0;
    background-color: #fff;
    transition: all 0.3s ease;
    display: block; /* Assicura che l'input si comporti come un blocco */
    width: 100%; /* Garantisce larghezza piena */
    
    &:focus {
      border-color: #3b82f6;
      box-shadow: 0 0 0 0.2rem rgba(59, 130, 246, 0.25);
    }
    
    .dark-mode & {
      background-color: #2d3748;
      border-color: #4a5568;
      color: #e2e8f0;
      
      &:focus {
        border-color: #3b82f6;
      }
    }
  }
  
  .password-input-wrapper {
    position: relative;
    display: flex; /* Aggiunto display flex */
    width: 100%; /* Assicura che il wrapper prenda tutta la larghezza */
    min-height: 54px; /* Altezza minima uguale all'input */
    
    .login-input {
      flex: 1; /* Assicura che l'input prenda tutto lo spazio disponibile */
      padding-right: 40px; /* Spazio per il bottone */
      height: 54px !important; /* Forza l'altezza */
      min-height: 54px !important; /* Forza l'altezza minima */
    }
    
    .password-toggle-btn {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      color: #6c757d;
      cursor: pointer;
      z-index: 10; /* Aumentato z-index */
      display: flex; /* Aggiunto display flex */
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 0; /* Rimuovi padding per evitare problemi di dimensionamento */
      
      &:hover {
        color: #495057;
      }
      
      .dark-mode & {
        color: #a0aec0;
        
        &:hover {
          color: #cbd5e0;
        }
      }
    }
  }
  
  .forgot-password {
    font-size: 0.875rem;
    color: #3b82f6;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: #2563eb;
      text-decoration: underline;
    }
    
    .dark-mode & {
      color: #60a5fa;
      
      &:hover {
        color: #93c5fd;
      }
    }
  }
  
  .login-button {
    height: 54px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover:not(:disabled) {
      transform: translateY(-2px);
      box-shadow: 0 5px 15px rgba(59, 130, 246, 0.3);
    }
    
    &:active:not(:disabled) {
      transform: translateY(0);
    }
  }
  
  .help-text {
    font-size: 0.875rem;
    color: #6c757d;
    
    .dark-mode & {
      color: #a0aec0;
    }
  }
  
  .support-link {
    color: #3b82f6;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: #2563eb;
      text-decoration: underline;
    }
    
    .dark-mode & {
      color: #60a5fa;
      
      &:hover {
        color: #93c5fd;
      }
    }
  }
}

// Animazioni
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-card {
  animation: fadeIn 0.6s ease-out forwards;
}

// Invalid Feedback
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
  position: absolute; /* Posizionamento assoluto per non influire sull'altezza */
  bottom: -22px; /* Posizionato sotto il campo */
  left: 0;
}

/* Aggiunto per garantire che il FormGroup mantenga l'altezza quando ci sono errori */
.form-floating {
  position: relative;
  min-height: 58px; /* Altezza minima per il contenitore floating */
}